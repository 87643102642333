import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-comp flex flex-col bg-[#1D2A1D]">
      <div className="wrap flex justify-between">
        <div className="f-left flex flex-col  items-start">
          <img src="/images/logoW.svg" className="footer-logo" />
          <p className="copy-write mb-2">© 2025 BitLoc Inc. ™BitLoc</p>
          <p className="copy-write">
            225 King Street West, Suite 1000 <br />
            Toronto, Ontario, Canada M5V 3M2
          </p>
        </div>
        <div className="f-right flex">
          <div className="links-sec flex">
            <div className="social-links flex flex-col">
              <a
                href="https://www.facebook.com/profile.php?id=61555726588764"
                target="_blank"
                className="link"
              >
                Facebook
              </a>
              <a
                href="https://www.instagram.com/bitloc.io/"
                target="_blank"
                className="link"
              >
                Instagram
              </a>
              <a
                href="https://www.linkedin.com/company/bitloc-io/"
                target="_blank"
                className="link"
              >
                LinkedIn
              </a>
              <a
                href="https://twitter.com/Bitloc_io?s=11&t=Sd6a60JRV86djS-sx8k5aw"
                target="_blank"
                className="link"
              >
                Twitter
              </a>
            </div>
            <div className="social-links flex flex-col">
              <a
                href="https://primal.net/p/npub1arvttgek8vh8r6mnm2mhu5jajsaa3ez88zsuxfd4xx56luly7xeqtywehc"
                target="_blank"
                className="link"
              >
                Nostr
              </a>
              <Link to="/faqs" className="link">
                FAQ
              </Link>
              <a href="/security" className="link">
                Security
              </a>
            </div>
            <div className="social-links flex flex-col">
              <Link to="/terms/TermsofService" className="link">
                Terms Of Service
              </Link>
              <a href="/terms/PrivacyPolicy" className="link">
                Privacy Policy
              </a>

              <a href="/terms/RiskDisclosure" className="link">
                Risk Disclosure
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
