import React, { useState } from "react";
import Wrapper from "../../routes/Wrapper";
import ClipboardJS from "clipboard";

const ContactUs = () => {
  const textToCopy = `-----BEGIN PGP PUBLIC KEY BLOCK-----  mDMEZQnvgBYJKwYBBAHaRw8BAQdAUmJY7ieG8udFj5JA+52LnztaJVnpdTfKZipI mNhcZLK0F0JpdGxvYyA8aW5mb0BiaXRsb2MuaW8+iJkEExYKAEEWIQSRGjGhgdlf GcKjNTziUvfrrQ2p/gUCZQnvgAIbAwUJBaTCAAULCQgHAgIiAgYVCgkICwIEFgID AQIeBwIXgAAKCRDiUvfrrQ2p/knUAQCgy8k49hWIu29ntvq9/1OKGSubGIYNkgNQ WoO4wtvTwQEAp76I9QiuNBv8S0c6BJ1lvvZjqdBJhPGbu5aEULGedgq4OARlCe+A EgorBgEEAZdVAQUBAQdAgIUHJNl3lFSL7EKw+8esehsmZjKimZ8im5PlhHxFKz8D AQgHiH4EGBYKACYWIQSRGjGhgdlfGcKjNTziUvfrrQ2p/gUCZQnvgAIbDAUJBaTC AAAKCRDiUvfrrQ2p/sUBAP9sbYbVX9pzC4DK+q2wU0+85ncIc10FgyCQ1w6D+IVn AgD+LpFZ5NN1P8T31Wj7WvxC2kJhvSyMKY/EkuoesIMa7gk= =zKcO -----END PGP PUBLIC KEY BLOCK-----`;

  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
    phoneNumber: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleCopyToClipboard = () => {
    const clipboard = new ClipboardJS(".btn-copy", {
      text: () => textToCopy,
    });

    clipboard.on("success", (e) => {
      e.clearSelection();
      clipboard.destroy();
    });

    clipboard.on("error", () => {
      clipboard.destroy();
    });
    document.querySelector(".btn-copy").click();
  };
  return (
    <Wrapper>
      <div className="contact-us-page h-screen flex flex-col">
        <div className="wrap wrapWidth flex flex-col">
          <div className=" flex items-center justify-between">
            <div>
              <h1 className="page-title">Contact Us</h1>
            </div>

            <div className="flex">
              <a
                href="tel:+1 647-692-2489"
                className="text-2xl text-white hover:underline"
              >
                +1 647-692-2489
              </a>
            </div>
          </div>
          <form className="form-block flex flex-col">
            <div className="input-field flex flex-col">
              <h1 className="field-tag">Name *</h1>
              <input
                type="text"
                name="name"
                className="txt cleanbtn"
                onChange={handleInputs}
                value={state.name}
                placeholder="Enter name"
              />
            </div>
            <div className="input-field flex flex-col">
              <h1 className="field-tag">Email *</h1>
              <input
                type="email"
                name="email"
                className="txt cleanbtn"
                onChange={handleInputs}
                value={state.email}
                placeholder="Enter email..."
              />
            </div>
            <div className="input-field flex flex-col">
              <h1 className="field-tag">Phone Number</h1>
              <input
                type="phone"
                name="phoneNumber"
                className="txt cleanbtn"
                onChange={handleInputs}
                value={state.phoneNumber}
                placeholder="Enter Phone Number"
              />
            </div>
            <div className="input-field flex flex-col">
              <h1 className="field-tag">Message *</h1>
              <textarea
                type="text"
                name="message"
                className="txt cleanbtn min-h-[200px]"
                onChange={handleInputs}
                value={state.message}
                placeholder="Enter your message here..."
              />
            </div>

            <div className="actions flex items-center">
              <button className="btn-login button">Submit</button>
            </div>
          </form>
          <div className="contact-us-btm flex flex-col">
            <p className="btm-description">
              Important Notice: If you send us an encrypted message and do not
              include your public key, please be aware that our response will be
              sent in clear text via email. To ensure secure and private
              correspondence, kindly include your public key when sending
              encrypted messages. Thank you for your understanding.
            </p>
            <div className="code-block flex items-center justify-center">
              <div className="code">
                <p
                  className=" overflow-hidden11"
                  // style={{ whiteSpace: "nowrap" }}
                >
                  -----BEGIN PGP PUBLIC KEY BLOCK----- <br />
                  mDMEZQnvgBYJKwYBBAHaRw8BAQdAUmJY7ieG8udFj5JA+52LnztaJVnpdTfKZipI
                  mNhcZLK0F0JpdGxvYyA8aW5mb0BiaXRsb2MuaW8+iJkEExYKAEEWIQSRGjGhgdlf
                  GcKjNTziUvfrrQ2p/gUCZQnvgAIbAwUJBaTCAAULCQgHAgIiAgYVCgkICwIEFgID
                  AQIeBwIXgAAKCRDiUvfrrQ2p/knUAQCgy8k49hWIu29ntvq9/1OKGSubGIYNkgNQ
                  WoO4wtvTwQEAp76I9QiuNBv8S0c6BJ1lvvZjqdBJhPGbu5aEULGedgq4OARlCe+A
                  EgorBgEEAZdVAQUBAQdAgIUHJNl3lFSL7EKw+8esehsmZjKimZ8im5PlhHxFKz8D
                  AQgHiH4EGBYKACYWIQSRGjGhgdlfGcKjNTziUvfrrQ2p/gUCZQnvgAIbDAUJBaTC
                  AAAKCRDiUvfrrQ2p/sUBAP9sbYbVX9pzC4DK+q2wU0+85ncIc10FgyCQ1w6D+IVn
                  AgD+LpFZ5NN1P8T31Wj7WvxC2kJhvSyMKY/EkuoesIMa7gk= =zKcO
                  <br />
                  -----END PGP PUBLIC KEY BLOCK-----
                </p>
              </div>
              <button
                className="btn-copy button"
                onClick={handleCopyToClipboard}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ContactUs;
